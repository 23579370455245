/* eslint-disable react/prefer-stateless-function */
import React from "react"
import { Link } from "gatsby"
// Packages
import { Row, Col, Container } from "react-bootstrap"
import Layout from "../components/layout"


const NotFound = () => {
  return (
    <Layout>
      
      <div>
        <Container className="mt-5 mb-5 pt-5">
          <Row className="shadow-12">
            <Col className="p-4">
              <h2>
                404: Like the perfect partner, this page doesn&apos;t exist.
              </h2>
              <h4>
                You have found the perfect tool to help grow your sport or
                activity business, however. Go to our{" "}
                <Link to="/">our home page</Link> to find out about how
                Signaclass can help your business grow.
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default NotFound
